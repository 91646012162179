import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import RadioGroupField from "../Inputs/RadioGroupField";
import { FormContext } from "../context/FormProvider";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";

const SafetyServices = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const [disabledInputs, setDisabledInputs] = useState([]);
  const {
    activeUserId,
    slide9,
    setSlide9,
    setIsLoading,
    requestStatus,
    populateInvalidInputs,
    disabledInputCheck,
  } = useContext(FormContext);

  const { projectId, clientId, user } = useContext(DashboardContext);

  const slide9Mutation = useSetSlide(
    clientId,
    projectId,
    9,
    setIsLoading,
    setSlideStatus,
  );

  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "9",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded && (
        <>
          <RadioGroupField
            idProp="level1Safety"
            label="Level 1 Safety Services"
            state={slide9}
            setState={setSlide9}
            val={slide9.level1Safety}
            debouncedSave={slide9Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck("level1Safety", disabledInputs)}
          />
          <RadioGroupField
            idProp="level2Safety"
            label="Level 2 Safety Services"
            state={slide9}
            setState={setSlide9}
            val={slide9.level2Safety}
            debouncedSave={slide9Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck("level2Safety", disabledInputs)}
          />
        </>
      )}
    </>
  );
};

export default SafetyServices;
