import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { DashboardContext } from "../../../../DashboardContext/DashboardContextProvider";

const submitForm = async (clientId, projectId, slide11) => {
  try {
    const res = await axios.patch(
      `/api/submit-form/${clientId}/${projectId}/`,
      {
        additionalInformation: slide11.additionalInformation,
        submitter: slide11.submitter,
        submitterPhone: slide11.submitterPhone,
        submitterEmail: slide11.submitterEmail,
        submitterTitle: slide11.submitterTitle,
      },
      { withCredentials: true },
    );
    return res.data;
  } catch (error) {
    console.log("SUBMIT FORM ERROR: ", error);
  }
};

export default function useSubmitForm(
  clientId,
  projectId,
  isReady,
  setIsReady,
  enqueueSnackbar,
  setIsLoading,
  slide11,
) {
  const { queryClient, isAdmin, navigate, userId } =
    useContext(DashboardContext);

  const submit = useMutation({
    mutationFn: () => {
      if (isReady === false) {
        return;
      } else {
        return submitForm(clientId, projectId, slide11);
      }
    },
    onError: (error) => {
      enqueueSnackbar(
        "There was an error submitting the form. Please try again.",
      );
      setIsReady(false);
    },
    onSuccess: (data) => {
      enqueueSnackbar(
        "Questionnaire is successfully submitted and is now pending review from Argus",
        {
          variant: "success",
          autoHideDuration: 3000,
        },
      );
      if (!isAdmin) {
        queryClient.invalidateQueries(["clientRows", clientId, userId]);
        navigate(`/dashboard/client/${clientId}`, { replace: true });
      } else {
        queryClient.invalidateQueries(["adminRows"]);
        navigate("/dashboard/admin", { replace: true });
      }
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  return submit;
}
