import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import FileField from "../Inputs/FileField.jsx";
import { FormContext } from "../context/FormProvider";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";
import "./styles/fileslide.css";

// Slide 10: FileSlide
const FileSlide = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const [disabledInputs, setDisabledInputs] = useState([]);
  const {
    slide1,
    slide2,
    slide4,
    slide10,
    setSlide10,
    setIsLoading,
    requestStatus,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  const [isUploading, setIsUploading] = useState(false);

  const slide10Mutation = useSetSlide(
    clientId,
    projectId,
    10,
    setIsLoading,
    setSlideStatus,
  );

  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "10",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <p className="important-text">
            If available, please provide the following information and/or
            documents in PDF format.
          </p>
          <FileField
            idProp={"dustPermit"}
            label={"Dust Permit"}
            state={slide10}
            setState={setSlide10}
            disabledInput={disabledInputCheck("dustPermit", disabledInputs)}
            disabledDustPermitNumber={disabledInputCheck(
              "dustPermitNumber",
              disabledInputs,
            )}
            setIsLoading={setIsLoading}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            val={slide10.dustPermit.status}
            debouncedSave={slide10Mutation}
            invalidData={getInvalidData(
              "dustPermitNumber",
              invalidInputsData,
              "10",
            )}
          />
          <FileField
            idProp={"gradingPlan"}
            label={"Grading & Drainage Plans"}
            state={slide10}
            setState={setSlide10}
            disabledInput={disabledInputCheck("gradingPlan", disabledInputs)}
            setIsLoading={setIsLoading}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            val={slide10.gradingPlan.status}
            debouncedSave={slide10Mutation}
          />
          <FileField
            idProp={"swmpPlan"}
            label={"Existing SWMP/SWPPP Site Plan"}
            state={slide10}
            setState={setSlide10}
            disabledInput={disabledInputCheck("swmpPlan", disabledInputs)}
            setIsLoading={setIsLoading}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            val={slide10.swmpPlan.status}
            debouncedSave={slide10Mutation}
          />
          <FileField
            idProp={"constructionSchedule"}
            label={"Construction Schedule"}
            state={slide10}
            setState={setSlide10}
            disabledInput={disabledInputCheck(
              "constructionSchedule",
              disabledInputs,
            )}
            setIsLoading={setIsLoading}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            val={slide10.constructionSchedule.status}
            debouncedSave={slide10Mutation}
          />
          <FileField
            idProp={"projectSpecifications"}
            label={"Project Specifications"}
            state={slide10}
            setState={setSlide10}
            disabledInput={disabledInputCheck(
              "projectSpecifications",
              disabledInputs,
            )}
            setIsLoading={setIsLoading}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            val={slide10.projectSpecifications.status}
            debouncedSave={slide10Mutation}
          />
          {(!slide1.underAcr || +slide4.distAcr >= 1) && (
            <>
              <FileField
                idProp={"landscapePlan"}
                label={"Landscape Plans"}
                state={slide10}
                setState={setSlide10}
                disabledInput={disabledInputCheck(
                  "landscapePlan",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide10.landscapePlan.status}
                debouncedSave={slide10Mutation}
              />
              <FileField
                idProp={"drainageReport"}
                label={"Drainage Report"}
                state={slide10}
                setState={setSlide10}
                disabledInput={disabledInputCheck(
                  "drainageReport",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide10.drainageReport.status}
                debouncedSave={slide10Mutation}
              />
              <FileField
                idProp={"soilsReport"}
                label={"Geotechnical/Soils Report"}
                state={slide10}
                setState={setSlide10}
                disabledInput={disabledInputCheck(
                  "soilsReport",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide10.soilsReport.status}
                debouncedSave={slide10Mutation}
              />
              <FileField
                idProp={"environmentalReport"}
                label={"Phase I or II Environmental Report"}
                state={slide10}
                setState={setSlide10}
                disabledInput={disabledInputCheck(
                  "environmentalReport",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide10.environmentalReport.status}
                debouncedSave={slide10Mutation}
              />
              <FileField
                idProp={"endangeredSpeciesReport"}
                label={"Biological or Endangered Species Report"}
                state={slide10}
                setState={setSlide10}
                disabledInput={disabledInputCheck(
                  "endangeredSpeciesReport",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide10.endangeredSpeciesReport.status}
                debouncedSave={slide10Mutation}
              />
              <FileField
                idProp={"archeologicalReport"}
                label={"Archeological Report"}
                state={slide10}
                setState={setSlide10}
                disabledInput={disabledInputCheck(
                  "archeologicalReport",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide10.archeologicalReport.status}
                debouncedSave={slide10Mutation}
              />
              <FileField
                idProp={"corpOfEngineersReport"}
                label={"Corp Of Engineers 404 Report and Permit"}
                state={slide10}
                setState={setSlide10}
                disabledInput={disabledInputCheck(
                  "corpOfEngineersReport",
                  disabledInputs,
                )}
                setIsLoading={setIsLoading}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                val={slide10.corpOfEngineersReport.status}
                debouncedSave={slide10Mutation}
              />
              {slide1.onNativeLand && (
                <FileField
                  idProp={"listOfSubcontractors"}
                  label={"List of Subcontractors"}
                  state={slide10}
                  setState={setSlide10}
                  disabledInput={disabledInputCheck(
                    "listOfSubcontractors",
                    disabledInputs,
                  )}
                  setIsLoading={setIsLoading}
                  isUploading={isUploading}
                  setIsUploading={setIsUploading}
                  val={slide10.listOfSubcontractors.status}
                  debouncedSave={slide10Mutation}
                />
              )}
            </>
          )}
          {!slide2.inspectionRequest && (
            <FileField
              idProp={"inspectorQualifications"}
              label={"SWPPP Inspector Qualifications"}
              state={slide10}
              setState={setSlide10}
              disabledInput={disabledInputCheck(
                "inspectorQualifications",
                disabledInputs,
              )}
              setIsLoading={setIsLoading}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              val={slide10.inspectorQualifications.status}
              debouncedSave={slide10Mutation}
            />
          )}
        </>
      ) : (
        <p>Loading Page</p>
      )}
    </>
  );
};

export default FileSlide;
