import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import StarIcon from "@mui/icons-material/Star";
import SvgIcon from "@mui/material/SvgIcon";
import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import { FormContext } from "../context/FormProvider";
import useInvalidInputs from "../Slides/Hooks/useInvalidInputs";
import ColorsLegend from "./ColorsLegend";
import "./styles/timeline-container.css";

const TimelineItem = ({ slide, label, status }) => {
  const { pageNumber, setPageNumber } = useContext(FormContext);

  return (
    <div
      className={`timeline-item ${slide === 11 ? "last-slide-item" : ""}`}
      onClick={() => {
        if (slide !== pageNumber) {
          setPageNumber(+slide);
        }
      }}
    >
      <div className="column1">
        {/* If odd, label on the left */}
        {slide % 2 !== 0 && (
          <p
            className={`timeline-item-text left-text ${
              slide === pageNumber ? "active-text" : `${status}-text`
            }`}
          >
            {label}
          </p>
        )}
      </div>
      {/* Center column: dot and connector */}
      <div className="timeline-separator">
        {slide === pageNumber ? (
          <SvgIcon className="timeline-icon active-icon" component={StarIcon} />
        ) : status === "incomplete" ? (
          <SvgIcon
            className={`timeline-icon ${status}-icon`}
            component={DangerousIcon}
          />
        ) : status === "complete" ? (
          <SvgIcon
            className={`timeline-icon ${status}-icon`}
            component={CheckCircleIcon}
          />
        ) : status === "reviseSlide" ? (
          <SvgIcon
            className={`timeline-icon ${status}-icon`}
            component={AssignmentLateIcon}
          />
        ) : status === "editedSlide" ? (
          <SvgIcon
            className={`timeline-icon ${status}-icon`}
            component={AssignmentLateIcon}
          />
        ) : null}
        {slide !== 11 && (
          <span className={`timeline-connector ${status}-line`}></span>
        )}
      </div>
      <div className="column3">
        {/* If even, label on the right */}
        {slide % 2 === 0 && (
          <p
            className={`timeline-item-text right-text ${
              slide === pageNumber ? "active-text" : `${status}-text`
            }`}
          >
            {label}
          </p>
        )}
      </div>
    </div>
  );
};

const NewTimeline = ({
  slideStatus,
  invalidSlides,
  setInvalidSlideNumbers,
}) => {
  const {
    pageNumber,
    setPageNumber,
    requestStatus,
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    slide9,
    slide10,
    slide11,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  const { data: invalidInputsData, isFetched: invalidInputsFetched } =
    useInvalidInputs(clientId, projectId, requestStatus);

  const [invalidSlideValues, setInvalidSlideValues] = useState({});

  useEffect(() => {
    if (!projectId) return;

    if (
      invalidInputsFetched &&
      invalidInputsData &&
      invalidInputsData.length > 0
    ) {
      const invalidSlideNumbers = invalidInputsData.map(
        (invalidInput) => +invalidInput.slide,
      );

      let uniqueInvalidSlideNumbers = [...new Set(invalidSlideNumbers)].sort(
        (a, b) => a - b,
      );

      setInvalidSlideNumbers(uniqueInvalidSlideNumbers);

      let invalidSlideValues = {};

      for (let i = 0; i < invalidInputsData.length; i++) {
        const invalidInput = invalidInputsData[i];
        let slide = invalidInput.slide;
        let name = invalidInput.name;
        let value = invalidInput.initialValue;
        if (value === "true") {
          value = true;
        }
        if (value === "false") {
          value = false;
        }
        if (!invalidSlideValues[`slide${slide}`]) {
          invalidSlideValues[`slide${slide}`] = {};
        }
        invalidSlideValues[`slide${slide}`][name] = value;
      }
      setInvalidSlideValues(() => invalidSlideValues);
    }
  }, [invalidInputsFetched, invalidInputsData]);

  const checkInvalidSlideStatus = (slideNumber, slideState, invalidInputs) => {
    if (!invalidSlides || !invalidSlides.includes(slideNumber)) {
      return;
    }

    let slideStr = `slide${slideNumber}`;
    let style = `editedSlide`;

    for (const [key, value] of Object.entries(slideState)) {
      if (!invalidInputs[slideStr]) continue;
      if (
        invalidInputs[slideStr][key] &&
        value === invalidInputs[slideStr][key]
      ) {
        style = `reviseSlide`;
        break;
      }
    }

    return style;
  };

  return (
    <div className="timeline-container">
      <div className="timeline">
        <header className="timeline-header">
          <h2 id="timeline-heading">Quick Navigation</h2>
        </header>
        <div className="timeline-content">
          <TimelineItem
            slide={1}
            label="Start"
            status={
              requestStatus === "RV" && invalidSlides.includes(1)
                ? checkInvalidSlideStatus(1, slide1, invalidSlideValues)
                : slideStatus["slide1"]
            }
          />
          <TimelineItem
            slide={2}
            label="SWPPP Services"
            status={
              requestStatus === "RV" && invalidSlides.includes(2)
                ? checkInvalidSlideStatus(2, slide2, invalidSlideValues)
                : slideStatus["slide2"]
            }
          />
          <TimelineItem
            slide={3}
            label="Project Type"
            status={
              requestStatus === "RV" && invalidSlides.includes(3)
                ? checkInvalidSlideStatus(3, slide3, invalidSlideValues)
                : slideStatus["slide3"]
            }
          />
          <TimelineItem
            slide={4}
            label="Project Information"
            status={
              requestStatus === "RV" && invalidSlides.includes(4)
                ? checkInvalidSlideStatus(4, slide4, invalidSlideValues)
                : slideStatus["slide4"]
            }
          />
          <TimelineItem
            slide={5}
            label="Project Location"
            status={
              requestStatus === "RV" && invalidSlides.includes(5)
                ? checkInvalidSlideStatus(5, slide5, invalidSlideValues)
                : slideStatus["slide5"]
            }
          />
          <TimelineItem
            slide={6}
            label="Owning Entity Info."
            status={
              requestStatus === "RV" && invalidSlides.includes(6)
                ? checkInvalidSlideStatus(6, slide6, invalidSlideValues)
                : slideStatus["slide6"]
            }
          />
          <TimelineItem
            slide={7}
            label="Operator/GC Info."
            status={
              requestStatus === "RV" && invalidSlides.includes(7)
                ? checkInvalidSlideStatus(7, slide7, invalidSlideValues)
                : slideStatus["slide7"]
            }
          />
          <TimelineItem
            slide={8}
            label="Dust Services"
            status={
              requestStatus === "RV" && invalidSlides.includes(8)
                ? checkInvalidSlideStatus(8, slide8, invalidSlideValues)
                : slideStatus["slide8"]
            }
          />
          <TimelineItem
            slide={9}
            label="Safety Services"
            status={slideStatus["slide9"]}
          />
          <TimelineItem
            slide={10}
            label="Document Checklist"
            status={slideStatus["slide10"]}
          />
          <TimelineItem slide={11} label="Submit" status={"complete"} />
        </div>
        <ColorsLegend requestStatus={requestStatus} />
      </div>
    </div>
  );
};

export default NewTimeline;
