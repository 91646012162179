import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../Helper/formbtnhelper";
import EmailField from "../Inputs/EmailField";
import PhoneField from "../Inputs/PhoneField";
import SelectField from "../Inputs/SelectField";
import TextField from "../Inputs/TextField";
import TitleField from "../Inputs/TitleField";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";

// Slide 7: General Contractor/Operator Information
const GCInformation = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const [disabledInputs, setDisabledInputs] = useState([]);
  const {
    slide1,
    slide4,
    slide6,
    slide7,
    setSlide7,
    setIsLoading,
    requestStatus,
    names,
    phoneNumbers,
    emails,
    titles,
    companies,
    regulatoryAgencies,
    predictiveTextFetched,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
    disableAll,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  // ^ On Mount, the page should scroll to the top
  useEffect(() => {
    scrollToFirstRequiredField(slide7);
  }, []);

  // : MAIN QUERY LOGIC :

  const slide7Mutation = useSetSlide(
    clientId,
    projectId,
    7,
    setIsLoading,
    setSlideStatus,
  );

  useEffect(() => {
    let newState = {};
    if (slide6.ownerGCCheck === true && slide6.oName !== slide7.gcName) {
      newState = {
        ...slide7,
        gcName: slide6.oName,
        gcAdd: slide6.oAdd,
        gcCity: slide6.oCity,
        gcZip: slide6.oZip,
        gcPhone: slide6.oPhone,
      };
      setSlide7(() => newState);
      slide7Mutation.mutate(newState);
      return;
    } else if (
      slide6.ownerGCCheck === false &&
      slide6.oName === slide7.gcName
    ) {
      newState = {
        ...slide7,
        gcName: "",
        gcAdd: "",
        gcCity: "",
        gcZip: "",
        gcPhone: "",
      };
      setSlide7(() => newState);
      slide7Mutation.mutate(newState);
      return;
    }
  }, [slide6.ownerGCCheck]);

  useEffect(() => {
    if (disableAll) return;
    if (slide6.ownerGCCheck === true) return;
    if (slide7.gcName === "" || slide7.gcName.length < 4) return;
    for (const agency in regulatoryAgencies) {
      if (agency === slide7.gcName) {
        let newState = {
          ...slide7,
          gcPhone: regulatoryAgencies[agency].phone,
          gcAdd: regulatoryAgencies[agency].address,
          gcCity: regulatoryAgencies[agency].city,
          gcZip: regulatoryAgencies[agency].zip,
        };
        setSlide7(newState);
        slide7Mutation.mutate(newState);
      }
    }
  }, [slide7.gcName]);

  // : INVALID INPUTS LOGIC :

  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "7",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {/* {formStateLoaded ? ( */}
      <h2 className="question-section">
        Operator/General Contractor Company Information
      </h2>
      <p className="important-text">
        Please provide the following information about this projects primary
        Operator/General Contractor. All fields should match exactly as
        registered with the AZCC when forming the entity
      </p>
      <TextField
        idProp="gcName"
        label="Legal Name"
        state={slide7}
        setState={setSlide7}
        val={slide7.gcName}
        debouncedSave={slide7Mutation}
        type="company"
        setIsLoading={setIsLoading}
        autoData={predictiveTextFetched ? companies : []}
        // req={slide7.gcName === "" || slide7.gcName.length < 3}
        disabledInput={disabledInputCheck("gcName", disabledInputs)}
        disabledInputMessage={
          disabledInputCheck("gcName", disabledInputs) === false
            ? getInputMessage("gcName", invalidInputsData, "7")
            : ""
        }
        invalidData={getInvalidData("gcName", invalidInputsData, "7")}
      />
      <TextField
        idProp="gcAdd"
        label="Office Address"
        state={slide7}
        setState={setSlide7}
        val={slide7.gcAdd}
        debouncedSave={slide7Mutation}
        setIsLoading={setIsLoading}
        // req={slide7.gcAdd === "" || slide7.gcAdd.length < 6}
        disabledInput={disabledInputCheck("gcAdd", disabledInputs)}
        disabledInputMessage={
          disabledInputCheck("gcAdd", disabledInputs) === false
            ? getInputMessage("gcAdd", invalidInputsData, "7")
            : ""
        }
        invalidData={getInvalidData("gcAdd", invalidInputsData, "7")}
      />
      <TextField
        idProp="gcCity"
        label="City"
        state={slide7}
        setState={setSlide7}
        val={slide7.gcCity}
        debouncedSave={slide7Mutation}
        setIsLoading={setIsLoading}
        // req={slide7.gcCity === "" || slide7.gcCity.length < 3}
        disabledInput={disabledInputCheck("gcCity", disabledInputs)}
        disabledInputMessage={
          disabledInputCheck("gcCity", disabledInputs) === false
            ? getInputMessage("gcCity", invalidInputsData, "7")
            : ""
        }
        invalidData={getInvalidData("gcCity", invalidInputsData, "7")}
      />
      <SelectField
        idProp={"gcState"}
        label={"State"}
        state={slide7}
        setState={setSlide7}
        val={slide7.gcState}
        debouncedSave={slide7Mutation}
        setIsLoading={setIsLoading}
        disabledInput={disabledInputCheck("gcState", disabledInputs)}
        disabledInputMessage={
          disabledInputCheck("gcState", disabledInputs) === false
            ? getInputMessage("gcState", invalidInputsData, "7")
            : ""
        }
        invalidData={getInvalidData("gcState", invalidInputsData, "7")}
        type={"state"}
      />
      <TextField
        idProp="gcZip"
        label="Zip"
        state={slide7}
        setState={setSlide7}
        val={slide7.gcZip}
        debouncedSave={slide7Mutation}
        setIsLoading={setIsLoading}
        // req={slide7.gcZip === "" || slide7.gcZip.length !== 5}
        disabledInput={disabledInputCheck("gcZip", disabledInputs)}
        disabledInputMessage={
          disabledInputCheck("gcZip", disabledInputs) === false
            ? getInputMessage("gcZip", invalidInputsData, "7")
            : ""
        }
        invalidData={getInvalidData("gcZip", invalidInputsData, "7")}
      />
      <PhoneField
        idProp="gcPhone"
        label="Office Phone"
        state={slide7}
        setState={setSlide7}
        val={slide7.gcPhone}
        debouncedSave={slide7Mutation}
        setIsLoading={setIsLoading}
        req={!isValidPhoneNumber(slide7.gcPhone)}
        autoData={predictiveTextFetched ? phoneNumbers : []}
        disabledInput={disabledInputCheck("gcPhone", disabledInputs)}
        disabledInputMessage={
          disabledInputCheck("gcPhone", disabledInputs) === false
            ? getInputMessage("gcPhone", invalidInputsData, "7")
            : ""
        }
        invalidData={getInvalidData("gcPhone", invalidInputsData, "7")}
      />
      {!slide1.underAcr || +slide4.distAcr >= 1 ? (
        <>
          <h2 className="question-section">Responsible Officer Information</h2>
          <p className="important-text">
            Please provide the following information for the individual who has
            the authority to make legal decisions and act on behalf of the
            Operator/General Contractor. This individual will be certifying the
            NOI.
          </p>
          <TextField
            idProp="gcContactName"
            label="Full Name"
            state={slide7}
            setState={setSlide7}
            val={slide7.gcContactName}
            debouncedSave={slide7Mutation}
            type="name"
            setIsLoading={setIsLoading}
            req={!isValidName(slide7.gcContactName)}
            autoData={predictiveTextFetched ? names : []}
            disabledInput={disabledInputCheck("gcContactName", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("gcContactName", disabledInputs) === false
                ? getInputMessage("gcContactName", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "gcContactName",
              invalidInputsData,
              "7",
            )}
          />
          <TitleField
            idProp="gcContactTitle"
            label="Title"
            state={slide7}
            setState={setSlide7}
            val={slide7.gcContactTitle}
            debouncedSave={slide7Mutation}
            type="title"
            setIsLoading={setIsLoading}
            // req={
            //   slide7.gcContactTitle === "" ||
            //   slide7.gcContactTitle.length < 3
            // }
            autoData={predictiveTextFetched ? titles : []}
            disabledInput={disabledInputCheck("gcContactTitle", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("gcContactTitle", disabledInputs) === false
                ? getInputMessage("gcContactTitle", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "gcContactTitle",
              invalidInputsData,
              "7",
            )}
          />
          <PhoneField
            idProp="gcContactPhone"
            label="Phone Number"
            state={slide7}
            setState={setSlide7}
            val={slide7.gcContactPhone}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={!isValidPhoneNumber(slide7.gcContactPhone)}
            autoData={predictiveTextFetched ? phoneNumbers : []}
            disabledInput={disabledInputCheck("gcContactPhone", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("gcContactPhone", disabledInputs) === false
                ? getInputMessage("gcContactPhone", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "gcContactPhone",
              invalidInputsData,
              "7",
            )}
          />
          <EmailField
            idProp="gcContactEmail"
            label="Email Address"
            state={slide7}
            setState={setSlide7}
            val={slide7.gcContactEmail}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={!isValidEmail(slide7.gcContactEmail)}
            autoData={predictiveTextFetched ? emails : []}
            disabledInput={disabledInputCheck("gcContactEmail", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("gcContactEmail", disabledInputs) === false
                ? getInputMessage("gcContactEmail", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "gcContactEmail",
              invalidInputsData,
              "7",
            )}
          />
          <h2 className="question-section">Day-to-Day Contact Information</h2>
          <p className="important-text">
            Please provide the following information for the individual in
            charge of day-to-day operations, and has been delegated authority to
            act on behalf of the responsible officer.
          </p>
          <TextField
            idProp="gcContact2Name"
            label="Full Name"
            state={slide7}
            setState={setSlide7}
            val={slide7.gcContact2Name}
            debouncedSave={slide7Mutation}
            type="name"
            setIsLoading={setIsLoading}
            req={!isValidName(slide7.gcContact2Name)}
            autoData={predictiveTextFetched ? names : []}
            disabledInput={disabledInputCheck("gcContact2Name", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("gcContact2Name", disabledInputs) === false
                ? getInputMessage("gcContact2Name", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "gcContact2Name",
              invalidInputsData,
              "7",
            )}
          />
          <TitleField
            idProp="gcContact2Title"
            label="Title"
            state={slide7}
            setState={setSlide7}
            val={slide7.gcContact2Title}
            debouncedSave={slide7Mutation}
            type="title"
            setIsLoading={setIsLoading}
            // req={
            //   slide7.gcContact2Title === "" ||
            //   slide7.gcContact2Title.length < 3
            // }
            autoData={predictiveTextFetched ? titles : []}
            disabledInput={disabledInputCheck(
              "gcContact2Title",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("gcContact2Title", disabledInputs) === false
                ? getInputMessage("gcContact2Title", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "gcContact2Title",
              invalidInputsData,
              "7",
            )}
          />
          <PhoneField
            idProp="gcContact2Phone"
            label="Phone Number"
            state={slide7}
            setState={setSlide7}
            val={slide7.gcContact2Phone}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={!isValidPhoneNumber(slide7.gcContact2Phone)}
            autoData={predictiveTextFetched ? phoneNumbers : []}
            disabledInput={disabledInputCheck(
              "gcContact2Phone",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("gcContact2Phone", disabledInputs) === false
                ? getInputMessage("gcContact2Phone", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "gcContact2Phone",
              invalidInputsData,
              "7",
            )}
          />
          <EmailField
            idProp="gcContact2Email"
            label="Email Address"
            state={slide7}
            setState={setSlide7}
            val={slide7.gcContact2Email}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={!isValidEmail(slide7.gcContact2Email)}
            autoData={predictiveTextFetched ? emails : []}
            disabledInput={disabledInputCheck(
              "gcContact2Email",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("gcContact2Email", disabledInputs) === false
                ? getInputMessage("gcContact2Email", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "gcContact2Email",
              invalidInputsData,
              "7",
            )}
          />
          <h2 className="question-section">
            Field Operations Contact Information
          </h2>
          <p className="important-text">
            Please provide the following information for the individual in
            charge of field operations.
          </p>
          <TextField
            idProp="gcContact3Name"
            label="Full Name"
            state={slide7}
            setState={setSlide7}
            val={slide7.gcContact3Name}
            debouncedSave={slide7Mutation}
            type="name"
            setIsLoading={setIsLoading}
            req={!isValidName(slide7.gcContact3Name)}
            autoData={predictiveTextFetched ? names : []}
            disabledInput={disabledInputCheck("gcContact3Name", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("gcContact3Name", disabledInputs) === false
                ? getInputMessage("gcContact3Name", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "gcContact3Name",
              invalidInputsData,
              "7",
            )}
          />
          <TitleField
            idProp="gcContact3Title"
            label="Title"
            state={slide7}
            setState={setSlide7}
            val={slide7.gcContact3Title}
            debouncedSave={slide7Mutation}
            type="title"
            setIsLoading={setIsLoading}
            // req={false}
            autoData={predictiveTextFetched ? titles : []}
            disabledInput={disabledInputCheck(
              "gcContact3Title",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("gcContact3Title", disabledInputs) === false
                ? getInputMessage("gcContact3Title", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "gcContact3Title",
              invalidInputsData,
              "7",
            )}
          />
          <PhoneField
            idProp="gcContact3Phone"
            label="Phone Number"
            state={slide7}
            setState={setSlide7}
            val={slide7.gcContact3Phone}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={!isValidPhoneNumber(slide7.gcContact3Phone)}
            autoData={predictiveTextFetched ? phoneNumbers : []}
            disabledInput={disabledInputCheck(
              "gcContact3Phone",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("gcContact3Phone", disabledInputs) === false
                ? getInputMessage("gcContact3Phone", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "gcContact3Phone",
              invalidInputsData,
              "7",
            )}
          />
          <EmailField
            idProp="gcContact3Email"
            label="Email Address"
            state={slide7}
            setState={setSlide7}
            val={slide7.gcContact3Email}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={!isValidEmail(slide7.gcContact3Email)}
            autoData={predictiveTextFetched ? emails : []}
            disabledInput={disabledInputCheck(
              "gcContact3Email",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("gcContact3Email", disabledInputs) === false
                ? getInputMessage("gcContact3Email", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "gcContact3Email",
              invalidInputsData,
              "7",
            )}
          />
        </>
      ) : (
        <>
          <h2 className="question-section">Project Manager Information</h2>
          <TextField
            idProp="projectManager"
            label="Full Name"
            state={slide7}
            setState={setSlide7}
            val={slide7.projectManager}
            debouncedSave={slide7Mutation}
            type="name"
            setIsLoading={setIsLoading}
            req={!isValidName(slide7.projectManager)}
            autoData={predictiveTextFetched ? names : []}
            disabledInput={disabledInputCheck("projectManager", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("projectManager", disabledInputs) === false
                ? getInputMessage("projectManager", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "projectManager",
              invalidInputsData,
              "7",
            )}
          />
          <PhoneField
            idProp="projectManagerPhone"
            label="Phone Number"
            state={slide7}
            setState={setSlide7}
            val={slide7.projectManagerPhone}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={!isValidPhoneNumber(slide7.projectManagerPhone)}
            autoData={predictiveTextFetched ? phoneNumbers : []}
            disabledInput={disabledInputCheck(
              "projectManagerPhone",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("projectManagerPhone", disabledInputs) ===
              false
                ? getInputMessage("projectManagerPhone", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "projectManagerPhone",
              invalidInputsData,
              "7",
            )}
          />
          <EmailField
            idProp="projectManagerEmail"
            label="Email Address"
            state={slide7}
            setState={setSlide7}
            val={slide7.projectManagerEmail}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={!isValidEmail(slide7.projectManagerEmail)}
            autoData={predictiveTextFetched ? emails : []}
            disabledInput={disabledInputCheck(
              "projectManagerEmail",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("projectManagerEmail", disabledInputs) ===
              false
                ? getInputMessage("projectManagerEmail", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "projectManagerEmail",
              invalidInputsData,
              "7",
            )}
          />
          <h2 className="question-section">Superintendent Information</h2>
          <TextField
            idProp="superintendent"
            label="Full Name"
            state={slide7}
            setState={setSlide7}
            val={slide7.superintendent}
            debouncedSave={slide7Mutation}
            type="name"
            setIsLoading={setIsLoading}
            req={!isValidName(slide7.superintendent)}
            autoData={predictiveTextFetched ? names : []}
            disabledInput={disabledInputCheck("superintendent", disabledInputs)}
            disabledInputMessage={
              disabledInputCheck("superintendent", disabledInputs) === false
                ? getInputMessage("superintendent", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "superintendent",
              invalidInputsData,
              "7",
            )}
          />
          <PhoneField
            idProp="superintendentPhone"
            label="Phone Number"
            state={slide7}
            setState={setSlide7}
            val={slide7.superintendentPhone}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={!isValidPhoneNumber(slide7.superintendentPhone)}
            autoData={predictiveTextFetched ? phoneNumbers : []}
            disabledInput={disabledInputCheck(
              "superintendentPhone",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("superintendentPhone", disabledInputs) ===
              false
                ? getInputMessage("superintendentPhone", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "superintendentPhone",
              invalidInputsData,
              "7",
            )}
          />
          <EmailField
            idProp="superintendentEmail"
            label="Email Address"
            state={slide7}
            setState={setSlide7}
            val={slide7.superintendentEmail}
            debouncedSave={slide7Mutation}
            setIsLoading={setIsLoading}
            req={!isValidEmail(slide7.superintendentEmail)}
            autoData={predictiveTextFetched ? emails : []}
            disabledInput={disabledInputCheck(
              "superintendentEmail",
              disabledInputs,
            )}
            disabledInputMessage={
              disabledInputCheck("superintendentEmail", disabledInputs) ===
              false
                ? getInputMessage("superintendentEmail", invalidInputsData, "7")
                : ""
            }
            invalidData={getInvalidData(
              "superintendentEmail",
              invalidInputsData,
              "7",
            )}
          />
        </>
      )}
    </>
  );
};

export default GCInformation;
