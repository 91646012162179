import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import {
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../Helper/formbtnhelper";
import EmailField from "../Inputs/EmailField";
import PhoneField from "../Inputs/PhoneField";
import RadioGroupField from "../Inputs/RadioGroupField";
import TextField from "../Inputs/TextField";
import TitleField from "../Inputs/TitleField";
import { FormContext } from "../context/FormProvider";
import { scrollToFirstRequiredField } from "../main-helper";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";
import "./styles/dust-services.css";

// Slide 8: Dust Services
const DustServices = ({ setSlideStatus, slideState, formStateLoaded }) => {
  const [disabledInputs, setDisabledInputs] = useState([]);

  const {
    slide4,
    slide8,
    setSlide8,
    setIsLoading,
    requestStatus,
    names,
    phoneNumbers,
    emails,
    titles,
    predictiveTextFetched,
    populateInvalidInputs,
    getInvalidData,
    getInputMessage,
    disabledInputCheck,
  } = useContext(FormContext);

  const { clientId, projectId } = useContext(DashboardContext);

  // Scroll to required field or top
  useEffect(() => {
    scrollToFirstRequiredField(slide8);
  }, []);

  // Update Slide Logic
  const slide8Mutation = useSetSlide(
    clientId,
    projectId,
    8,
    setIsLoading,
    setSlideStatus,
  );

  // Invalid Inputs Logic
  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  // Populate Invalid Inputs
  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "8",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <RadioGroupField
            idProp="dustControlRequest"
            label="Dust Control Services?"
            state={slide8}
            setState={setSlide8}
            val={slide8.dustControlRequest}
            debouncedSave={slide8Mutation}
            setIsLoading={setIsLoading}
            disabledInput={disabledInputCheck(
              "dustControlRequest",
              disabledInputs,
            )}
          />
          {slide8.dustControlRequest && (
            <>
              <RadioGroupField
                idProp="manualLogsRequest"
                label="Dust Control Manual & Logs?"
                state={slide8}
                setState={setSlide8}
                val={slide8.manualLogsRequest}
                debouncedSave={slide8Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "manualLogsRequest",
                  disabledInputs,
                )}
              />
              {slide8.manualLogsRequest && (
                <>
                  <h2 className="question-section">
                    Dust Control Coordinator Information
                  </h2>
                  <p className="important-text">
                    {
                      "Please provide the contact information for the Dust Control Coordinator"
                    }
                  </p>
                  <TextField
                    idProp="dcc"
                    label="Full Name"
                    state={slide8}
                    setState={setSlide8}
                    val={slide8.dcc}
                    debouncedSave={slide8Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? names : []}
                    req={
                      slide8.manualLogsRequest &&
                      !isValidName(slide8.dcc, false)
                    }
                    type="name"
                    disabledInput={disabledInputCheck("dcc", disabledInputs)}
                    disabledInputMessage={
                      disabledInputCheck("dcc", disabledInputs) === false
                        ? getInputMessage("dcc", invalidInputsData, "8")
                        : ""
                    }
                    invalidData={getInvalidData("dcc", invalidInputsData, "8")}
                  />
                  <TitleField
                    idProp="dccTitle"
                    label="Title"
                    state={slide8}
                    setState={setSlide8}
                    val={slide8.dccTitle}
                    debouncedSave={slide8Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? titles : []}
                    req={slide8.manualLogsRequest && slide8.dccTitle === ""}
                    type="title"
                    disabledInput={
                      disabledInputCheck("dccTitle", disabledInputs) === true
                        ? true
                        : false
                    }
                    disabledInputMessage={
                      disabledInputCheck("dccTitle", disabledInputs) === false
                        ? getInputMessage("dccTitle", invalidInputsData, "8")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "dccTitle",
                      invalidInputsData,
                      "8",
                    )}
                  />
                  <PhoneField
                    idProp="dccPhone"
                    label="Phone Number"
                    state={slide8}
                    setState={setSlide8}
                    val={slide8.dccPhone}
                    debouncedSave={slide8Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? phoneNumbers : []}
                    req={
                      slide8.manualLogsRequest &&
                      !isValidPhoneNumber(slide8.dccPhone, false)
                    }
                    disabledInput={
                      disabledInputCheck("dccPhone", disabledInputs) === true
                        ? true
                        : false
                    }
                    disabledInputMessage={
                      disabledInputCheck("dccPhone", disabledInputs) === false
                        ? getInputMessage("dccPhone", invalidInputsData, "8")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "dccPhone",
                      invalidInputsData,
                      "8",
                    )}
                  />
                  <EmailField
                    idProp="dccEmail"
                    label="Email Address"
                    state={slide8}
                    setState={setSlide8}
                    val={slide8.dccEmail}
                    debouncedSave={slide8Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? emails : []}
                    req={
                      slide8.manualLogsRequest &&
                      !isValidEmail(slide8.dccEmail, false)
                    }
                    disabledInput={
                      disabledInputCheck("dccEmail", disabledInputs) === true
                        ? true
                        : false
                    }
                    disabledInputMessage={
                      disabledInputCheck("dccEmail", disabledInputs) === false
                        ? getInputMessage("dccEmail", invalidInputsData, "8")
                        : ""
                    }
                    invalidData={getInvalidData(
                      "dccEmail",
                      invalidInputsData,
                      "8",
                    )}
                  />
                </>
              )}
              <RadioGroupField
                idProp="dustAuditRequest"
                label="Dust Control Audits (Site/Logs)?"
                state={slide8}
                setState={setSlide8}
                val={slide8.dustAuditRequest}
                debouncedSave={slide8Mutation}
                setIsLoading={setIsLoading}
                disabledInput={disabledInputCheck(
                  "dustAuditRequest",
                  disabledInputs,
                )}
              />
              {slide8.dustAuditRequest && (
                <>
                  <h2 className="question-section">
                    Dust Audit Recipient Information
                  </h2>
                  <p className="important-text">
                    {
                      "Please provide the contact information for the individual who will need to receive the Dust Audit Reports"
                    }
                  </p>
                  <TextField
                    idProp="dustAuditContact"
                    label="Full Name"
                    state={slide8}
                    setState={setSlide8}
                    val={slide8.dustAuditContact}
                    debouncedSave={slide8Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? names : []}
                    req={
                      slide8.dustAuditRequest &&
                      !isValidName(slide8.dustAuditContact, false)
                    }
                    type="name"
                    disabledInput={disabledInputCheck(
                      "dustAuditContact",
                      disabledInputs,
                    )}
                    disabledInputMessage={
                      disabledInputCheck("dustAuditContact", disabledInputs) ===
                      false
                        ? getInputMessage(
                            "dustAuditContact",
                            invalidInputsData,
                            "8",
                          )
                        : ""
                    }
                    invalidData={getInvalidData(
                      "dustAuditContact",
                      invalidInputsData,
                      "8",
                    )}
                  />
                  <EmailField
                    idProp="dustAuditContactEmail"
                    label="Email Address"
                    state={slide8}
                    setState={setSlide8}
                    val={slide8.dustAuditContactEmail}
                    debouncedSave={slide8Mutation}
                    setIsLoading={setIsLoading}
                    autoData={predictiveTextFetched ? emails : []}
                    req={
                      slide8.dustAuditRequest &&
                      !isValidEmail(slide8.dustAuditContactEmail, false)
                    }
                    disabledInput={
                      disabledInputCheck(
                        "dustAuditContactEmail",
                        disabledInputs,
                      ) === true
                        ? true
                        : false
                    }
                    disabledInputMessage={
                      disabledInputCheck(
                        "dustAuditContactEmail",
                        disabledInputs,
                      ) === false
                        ? getInputMessage(
                            "dustAuditContactEmail",
                            invalidInputsData,
                            "8",
                          )
                        : ""
                    }
                    invalidData={getInvalidData(
                      "dustAuditContactEmail",
                      invalidInputsData,
                      "8",
                    )}
                  />
                </>
              )}
              {+slide4.distAcr >= 5 && (
                <>
                  <RadioGroupField
                    idProp="dustSignRequest"
                    label="Dust Control Sign?"
                    state={slide8}
                    setState={setSlide8}
                    val={slide8.dustSignRequest}
                    debouncedSave={slide8Mutation}
                    setIsLoading={setIsLoading}
                    disabledInput={disabledInputCheck(
                      "dustSignRequest",
                      disabledInputs,
                    )}
                  />
                  {slide8.dustSignRequest && (
                    <>
                      <h2 className="question-section">
                        Dust Sign Contact Information
                      </h2>
                      <p className="important-text">
                        {
                          "Please provide the contact information for the individual for the Dust Sign"
                        }
                      </p>
                      <TextField
                        idProp="dcName"
                        label="Contact for Dust Sign"
                        state={slide8}
                        setState={setSlide8}
                        val={slide8.dcName}
                        debouncedSave={slide8Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? names : []}
                        req={
                          slide8.dustSignRequest &&
                          !isValidName(slide8.dcName, false)
                        }
                        type="name"
                        disabledInput={disabledInputCheck(
                          "dcName",
                          disabledInputs,
                        )}
                        disabledInputMessage={
                          disabledInputCheck("dcName", disabledInputs) === false
                            ? getInputMessage("dcName", invalidInputsData, "8")
                            : ""
                        }
                        invalidData={getInvalidData(
                          "dcName",
                          invalidInputsData,
                          "8",
                        )}
                      />
                      <PhoneField
                        idProp="dcPhone"
                        label="Contact Phone"
                        state={slide8}
                        setState={setSlide8}
                        val={slide8.dcPhone}
                        debouncedSave={slide8Mutation}
                        setIsLoading={setIsLoading}
                        autoData={predictiveTextFetched ? phoneNumbers : []}
                        req={
                          slide8.dustSignRequest &&
                          !isValidPhoneNumber(slide8.dcPhone, false)
                        }
                        disabledInput={
                          disabledInputCheck("dcPhone", disabledInputs) === true
                            ? true
                            : false
                        }
                        disabledInputMessage={
                          disabledInputCheck("dcPhone", disabledInputs) ===
                          false
                            ? getInputMessage("dcPhone", invalidInputsData, "8")
                            : ""
                        }
                        invalidData={getInvalidData(
                          "dcPhone",
                          invalidInputsData,
                          "8",
                        )}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default DustServices;
