// import React from 'react';
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { debounce } from "lodash";
import { useCallback, useContext } from "react";
import { DashboardContext } from "../../../../DashboardContext/DashboardContextProvider";
import { FormContext } from "../../context/FormProvider";

// Update slide state by clientId, projectId, slide, and data
export const updateSlideState = async (
  clientId,
  projectId,
  slide,
  data,
  userId,
) => {
  if (projectId === null) return;

  let requestData = {};

  if (slide !== 10) {
    requestData = {
      ...data,
      activeUserId: userId,
    };
  } else {
    requestData = {
      data: data,
      activeUserId: userId,
    };
  }

  const slideState = await axios.patch(
    `/api/update-project-slide/${clientId}/${projectId}/${slide}/`,
    requestData,
    {
      withCredentials: true,
    },
  );

  return slideState.data;
};

// Custom hook to debounce an update to the slides state
export default function useSetSlide(
  clientId,
  projectId,
  slide,
  setIsLoading,
  setSlideStatus,
) {
  const { setRequestStatus, setActiveUserId } = useContext(FormContext);
  const { queryClient, userId } = useContext(DashboardContext);

  const setSlide = useMutation({
    mutationKey: ["currentProject", clientId, projectId, slide],
    mutationFn: (data) => {
      if (projectId === null || data === undefined) {
        return;
      } else {
        return updateSlideState(clientId, projectId, slide, data, userId);
      }
    },
    onError: (err, variables, context) => {
      if (projectId === null) return;
      console.log(`Error updating slide #${slide}: `, err);
    },
    onSuccess: async (data) => {
      setRequestStatus(data.requestStatus);

      setActiveUserId(data.activeUserId);
    },
    onSettled: (data) => {
      // Invalidate and refetch queries
      if (projectId !== null) {
        queryClient.invalidateQueries({
          queryKey: ["currentProject", clientId, projectId],
        });
      }

      queryClient.invalidateQueries({
        queryKey: ["predictiveText", clientId],
      });

      setIsLoading(false);
    },
  });

  // Waits 3.5 seconds before calling setSlide.mutate
  let debounceTime = 3500;
  const debouncedSave = useCallback(debounce(setSlide.mutate, debounceTime), [
    setSlide.mutate,
  ]);

  // Return the mutation and the debouncedSave function
  return { ...setSlide, mutate: debouncedSave };
}
