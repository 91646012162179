import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { DashboardContext } from "../../../../DashboardContext/DashboardContextProvider";

const getPresignedUrls = async (data) => {
  const { clientId, projectId, idProp, files } = data;
  try {
    const res = await axios.post(
      `/api/get-slide9-file-url/${clientId}/${projectId}/${idProp}/`,
      {
        files,
      },
      { withCredentials: true },
    );

    console.log("getPresignedUrl res: ", res);
    return res.data;
  } catch (err) {
    console.error("PRESIGNED URL ERROR", err);
  }
};

export const useGetPresignedUrls = (setIsUploading) => {
  return useMutation({
    mutationFn: getPresignedUrls,
    onError: (err) => {
      console.error("PRESIGNED URL ERROR", err);
      setIsUploading(false);
    },
  });
};

const initiateMultipartUpload = async (data) => {
  const { clientId, projectId, idProp, fileName, fileType, fileSize } = data;

  try {
    const res = await axios.post(
      `/api/initiate-multipart-upload/${clientId}/${projectId}/${idProp}/`,
      {
        fileName,
        fileType,
        fileSize,
      },
      { withCredentials: true },
    );

    console.log("INITIATE MULTIPART UPLOAD res: ", res);
    console.log("INITIATE MULTIPART UPLOAD data: ", res.data);
    return res.data;
  } catch (err) {
    console.error("INITIATE MULTIPART UPLOAD ERROR: ", err);
  }
};

export const useInitiateMultipartUpload = (setIsUploading) => {
  return useMutation({
    mutationFn: initiateMultipartUpload,
    onError: (err) => {
      console.error("INITIATE MULTIPART UPLOAD ERROR: ", err);
      setIsUploading(false);
    },
  });
};

const uploadPart = async ({ url, fileChunk }) => {
  console.log("Uploading Part URL: ", url);
  const res = await axios.put(url, fileChunk, {
    headers: {
      "Content-Type": "application/octet-stream",
    },
  });

  console.log("UPLOAD PART res: ", res);

  return res.headers.etag;
};

export const useUploadPart = () => {
  return useMutation({
    mutationFn: uploadPart,
    onError: (err) => {
      console.error("UPLOAD PART ERROR: ", err);
    },
  });
};

const completeMultipartUpload = async (data) => {
  const { clientId, projectId, idProp, filePath, uploadId, parts } = data;

  const res = await axios.post(
    `/api/complete-multipart-upload/${clientId}/${projectId}/${idProp}/`,
    {
      filePath,
      uploadId,
      parts,
    },
    { withCredentials: true },
  );
  console.log("COMPLETE MULTIPART UPLOAD res: ", res);

  return res.data;
};

export const useCompleteMultipartUpload = () => {
  return useMutation({
    mutationFn: completeMultipartUpload,
    onError: (err) => {
      console.error("COMPLETE MULTIPART UPLOAD ERROR: ", err);
    },
  });
};

const uploadFilesToS3 = async (data) => {
  console.log("uploadFilesToS3 data: ", data);
  const { presignedPosts, files, updateProgress } = data;

  const uploadPromises = files.map((file, index) => {
    const formData = new FormData();
    Object.keys(presignedPosts[index].fields).forEach((key) => {
      formData.append(key, presignedPosts[index].fields[key]);
    });

    formData.append("file", file);

    return axios.post(presignedPosts[index].url, formData, {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        updateProgress(percentCompleted, file.name);
      },
    });
  });

  return Promise.all(uploadPromises);
};

export const useUploadToS3 = (setIsUploading) => {
  return useMutation({
    mutationFn: uploadFilesToS3,
    onError: (err) => {
      console.error("UPLOAD TO S3 ERROR: ", err);
      setIsUploading(false);
    },
  });
};

const confirmFilesUpload = async (data) => {
  const { clientId, projectId, idProp, uploadedFiles } = data;

  try {
    const res = await axios.post(
      `/api/confirm-file-upload/${clientId}/${projectId}/${idProp}/`,
      { uploadedFiles }, // Send all file details at once
      { withCredentials: true },
    );

    console.log("confirmFilesUpload res: ", res);
    return res.data;
  } catch (err) {
    console.error("CONFIRM FILE UPLOAD ERROR", err);
  }
};

export const useConfirmFilesUpload = (setIsLoading, setIsUploading) => {
  const { queryClient } = useContext(DashboardContext);

  return useMutation({
    mutationFn: confirmFilesUpload,
    onError: (err) => {
      console.error("CONFIRM FILE UPLOAD ERROR: ", err);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["currentProject", data.clientId, data.projectId],
      });
      return data;
    },
    onSettled: () => {
      setIsLoading(false);
      setIsUploading(false);
    },
  });
};

const uploadFiles = async (data) => {
  const { clientId, projectId, idProp, files, userId } = data;

  const uploadPromises = files.map((file) => {
    //   const formData = new FormData();
    //   formData.append("file", file);
    //   formData.append("fileName", file.name);
    //   formData.append("userId", userId);

    //   return axios.post(
    //     `/api/upload-file/${clientId}/${projectId}/${idProp}/`,
    //     formData,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //       withCredentials: true,
    //     },
    //   );
    return axios.postForm(
      `/api/upload-form-file/${clientId}/${projectId}/${idProp}/`,
      {
        file: file,
        fileName: file.name,
        userId: userId,
      },
      {
        withCredentials: true,
      },
    );
  });

  return Promise.all(uploadPromises);
};

export const useUploadFiles = (setIsUploading, setState) => {
  const { queryClient } = useContext(DashboardContext);
  return useMutation({
    mutationFn: uploadFiles,
    onError: (err) => {
      console.error("UPLOAD FILES ERROR: ", err);
    },
    onSuccess: (data) => {
      console.log("UPLOAD FILES SUCCESS: ", data);
    },
    onSettled: (data) => {
      if (data) {
        queryClient.invalidateQueries({
          queryKey: ["currentProject", data.clientId, data.projectId],
        });
      }
      setIsUploading(false);
    },
  });
};

const deleteAllFiles = async (data) => {
  const { projectId, fileName } = data;

  try {
    const res = await axios.delete(
      `/api/delete-all-files/${projectId}/${fileName}/`,
      { withCredentials: true },
    );

    return res;
  } catch (err) {
    console.error(err);
  }
};

export const useDeleteFiles = () => {
  return useMutation({
    mutationFn: deleteAllFiles,
    onError: (err) => {
      console.error("DELETE FILES ERROR: ", err);
    },
  });
};

const deleteFile = async (data) => {
  const { projectId, idProp, uploadId } = data;
  console.log("DELETE FILE DATA: ", data);
  try {
    const res = await axios.delete(
      `/api/delete-form-file/${projectId}/${idProp}/${uploadId}/`,
      { withCredentials: true },
    );

    return res.data;
  } catch (err) {
    console.error(err);
  }
};

export const useDeleteFile = (
  clientId,
  projectId,
  idProp,
  state,
  setState,
  enqueueSnackbar,
) => {
  const { queryClient } = useContext(DashboardContext);
  return useMutation({
    mutationFn: (data) => deleteFile({ ...data, idProp, projectId }),
    onMutate: (data) => {
      const oldData = state[idProp];

      console.log("OLD DATA: ", oldData);
      console.log("DATA: ", data);

      setState((prevState) => ({
        ...prevState,
        [idProp]: {
          ...oldData,
          files: oldData.files.filter((file) => file.id !== data.uploadId),
        },
      }));

      return { oldData };
    },
    onError: (err, context) => {
      console.error("DELETE FILES ERROR: ", err);
      enqueueSnackbar("Error deleting file", {
        variant: "error",
        autoHideDuration: 3000,
      });

      if (context?.oldData) {
        setState((prevState) => ({
          ...prevState,
          [idProp]: context.oldData,
        }));
      }
    },
    onSuccess: (data) => {
      if (data) {
        // Update the state with the returned files
        setState((prevState) => ({
          ...prevState,
          [idProp]: {
            ...prevState[idProp],
            files: data.files, // Update with the new list of files
          },
        }));
        enqueueSnackbar("File deleted successfully", {
          variant: "success",
          autoHideDuration: 2500,
        });
      }
    },
    onSettled: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["currentProject", clientId, projectId],
      });
    },
  });
};
