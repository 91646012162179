import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import { FormContext } from "../context/FormProvider";
import RadioGroupField from "../Inputs/RadioGroupField";
import { scrollToFirstRequiredField } from "../main-helper";
import "../styles/form-container.css";
import useSetSlide from "./Hooks/setSlide";
import useInvalidInputs from "./Hooks/useInvalidInputs";
import "./styles/additionalinformation.css";

// Slide 11: Additional Information
const AdditionalInformation = ({
  setSlideStatus,
  slideState,
  formStateLoaded,
}) => {
  const [disabledInputs, setDisabledInputs] = useState([]);
  const {
    activeUserId,
    slide11,
    setSlide11,
    setIsLoading,
    requestStatus,
    populateInvalidInputs,
    disabledInputCheck,
  } = useContext(FormContext);

  const { projectId, clientId, user } = useContext(DashboardContext);

  // On Mount, the page should scroll to the top
  useEffect(() => {
    scrollToFirstRequiredField(slide11);
  }, []);

  useEffect(() => {
    if (user.id !== "argus_admin" && slide11.submitter !== user.name) {
      setSlide11({
        ...slide11,
        submitter: user.name,
        submitterEmail: user.email,
        submitterPhone: user.phone,
        submitterTitle:
          slide11.submitterTitle === "" && user.title !== ""
            ? user.title
            : slide11.submitterTitle,
      });
    }
  }, []);

  // Update Slide Logic
  const slide11Mutation = useSetSlide(
    clientId,
    projectId,
    11,
    setIsLoading,
    setSlideStatus,
  );

  // - Additional Information Event Handler -

  const handleTextAreaChange = (e) => {
    let newState = { ...slide11, [e.target.id]: e.target.value };
    setSlide11(newState);
    setIsLoading(() => true);
    slide11Mutation.mutate(newState);
  };

  // : INVALID INPUTS LOGIC :

  const invalidInputsData = useInvalidInputs(
    clientId,
    projectId,
    requestStatus,
  );

  useEffect(() => {
    populateInvalidInputs(
      invalidInputsData,
      disabledInputs,
      setDisabledInputs,
      "11",
    );
  }, [invalidInputsData.data, invalidInputsData.isFetched]);

  return (
    <>
      {formStateLoaded ? (
        <>
          <p className="important-text">
            Is there any additional information you would like Argus to know
            about this project?
          </p>
          <div className={"form-slides"}>
            <textarea
              className="additional-info"
              aria-label="Additional Information Text Area"
              id="additionalInformation"
              type="text"
              rows="3"
              value={slide11.additionalInformation}
              onChange={handleTextAreaChange}
              disabled={
                (requestStatus === "IP" && user.id !== activeUserId) ||
                requestStatus === "P" ||
                (requestStatus === "RV" &&
                  disabledInputCheck(
                    "additionalInformation",
                    disabledInputs,
                  ) === true)
              }
            ></textarea>
          </div>
        </>
      ) : (
        <p>Loading...</p>
      )}
    </>
  );
};

export default AdditionalInformation;
